export enum EBillingPlanCode {
  shipper = 'tms.plan.shipper',
  carrierBasic = 'tms.plan.carrier.basic',
  carrierExtended = 'tms.plan.carrier.extended',
  carrierStart = 'tms.plan.carrier.start',
  freightForwarderBasic = 'tms.plan.freight_forwarder.basic',
  freightForwarderExtended = 'tms.plan.freight_forwarder.extended',
  cargoOwner = 'tms.plan.cargo_owner',
}

export enum EBillingShortPlanCode {
  basic = 'basic',
  extended = 'extended',
  start = 'start',
}

export enum EBillingPlanGroup {
  shipper = 'shipper',
  carrier = 'carrier',
  freightForwarder = 'freight_forwarder',
  cargoOwner = 'cargo_owner',
}

export enum EBillingPlanCodeStatus {
  active = 'active',
  pending = 'pending',
}

export enum EBillingTransactionCode {
  countOfCompletedExecutionRequests = 'tms.count_of_completed_execution_requests',
  countOfCompletedBookings = 'tms.count_of_completed_bookings',
}

export enum EBillingPlaneCodePrice {
  carrierBasic = 1899,
  carrierExtended = 2899,
  carrierStart = 0,
  freightForwarderBasic = 2399,
  freightForwarderExtended = 4399,
}

export enum ETenantSubscriptionsEventType {
  subscriptionPlanUpdated = 'Events::BillingContext::SubscriptionPlanUpdated',
}
