import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  EVehicleType,
  EVehicleUnitType,
  VEHICLE_UNIT_BY_TYPE,
} from '@/contexts/resourcesControlContext/domain/constants';
import {
  TVehiclesResponse,
  TEmployeeResponse,
  TEmployeesResponse,
  TVehicleInformationResponse,
  TCreateCorporateAccountRequestPayload,
  TAutocompleteDrivers,
  TEditVehicleInformationRequest,
  TUserRolesResponse,
  TDepartmentsResponse,
  TEmployeeDriverLicense,
  TEmployeePassport,
  TCreateDonkeyPayload,
  TAutocompleteOrderVehiclesResponse,
} from '@/contexts/resourcesControlContext/domain/types';
import { getObjectWithoutEmptyFields } from '@/utils';
import { TEmployeeNotificationSettingsResponse }
  from '@/contexts/resourcesControlContext/views/employees/NotificationSettingsView/types';
import {
  TEntity,
  TFileData,
  TPagination,
  TDocumentResponse,
  TEntityWithFirstSecondLastName,
} from '@/types';
import tt from '@/i18n/utils/translateText';
import { TTransportType } from '@/contexts/orderingContext/domain/types';
import { TContractor, TContractorsResponse } from '@/contexts/contractorsContext/domain/types';

export const fetchVehicles = ({ type, pagination }: { type: EVehicleType, pagination: TPagination }) => {
  const vehicleUnitType = VEHICLE_UNIT_BY_TYPE[type];
  if (!vehicleUnitType) return Promise.reject();

  return sendApiRequest<TVehiclesResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/vehicles',
    params: {
      items: pagination.pageItems,
      page: pagination.currentPage,
      vehicleUnitType,
    },
  });
};

export const fetchResponsibleEmployee = () => sendApiRequest<TEntityWithFirstSecondLastName[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/orders/responsible_employees',
});

export const fetchTrailerTypes = () => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/trailer_types',
});

export const fetchTransportTypes = () => sendApiRequest<TTransportType[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/transport_types',
});

export const fetchTransportTypesAutocomplete = () => sendApiRequest<TTransportType[]>({
  method: httpMethods.GET,
  endpoint: '/autocomplete/transport_types',
});

export const fetchTruckTypes = () => sendApiRequest<TEntity[]>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/truck_types',
});

export const createDonkey = (payload: TCreateDonkeyPayload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/donkeys',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const changeDonkey = (id: string, payload: any) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/donkeys/${id}`,
  payload,
});

export const createTrailer = (trailer: TCreateDonkeyPayload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/trailers',
  payload: trailer,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const changeTrailer = (id: string, payload: any) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/trailers/${id}`,
  payload,
});

export const createTruck = (truck: TCreateDonkeyPayload) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/trucks',
  payload: truck,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const changeTruck = (id: string, payload: any) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/trucks/${id}`,
  payload,
});

export const fetchEmployees = ({
  pageItems = 10,
  page,
}: { pageItems: number, page: number }) => sendApiRequest<TEmployeesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/employees',
  params: {
    items: pageItems,
    page,
  },
});

export const getResponsibleOnLookupAssignmentRules = ({
  _pageItems,
  _page,
}: { _pageItems: number, _page: number }) => new Promise((resolve) => {
  const data = [
    {
      id: '34143736-a1ac-4c5f-b4ec-a1c94fa3cf5d',
      senderName: null,
      regionIsoCodes: null,
      federalDistricts: null,
      isDefault: true,
      customerTenant: {
        id: null,
        name: null,
        inn: null,
        phone: null,
        partnershipStatus: null,
        employees: [] as [],
      },
      responsibleEmployee: {
        id: '692274e5-6fd9-49ba-bc44-13f1f51626f5',
        firstName: 'Лаврентий',
        lastName: 'Овчинников',
        secondName: null,
      },
    },
    {
      id: 'b9ecf202-ec50-4417-b83e-d35a99a4be47',
      senderName: 'ООО ИркутскТрейд',
      regionIsoCodes: ['RU-ZAB', 'RU-BU'],
      federalDistricts: ['SKFO', 'UFO'],
      isDefault: false,
      customerTenant: {
        id: null,
        name: 'ЗАО Мясников',
        inn: null,
        phone: null,
        partnershipStatus: null,
        employees: [] as [],
      },
      responsibleEmployee: {
        id: '692274e5-6fd9-49ba-bc44-13f1f51626f5',
        firstName: 'Лаврентий',
        lastName: 'Овчинников',
        secondName: null,
      },
    },
    {
      id: '21d011da-0a30-416d-bba7-061add4d4cbf',
      senderName: 'ОАО Панфилов',
      regionIsoCodes: ['RU-ARK', 'RU-VLG'],
      federalDistricts: ['UFO', 'SKFO'],
      isDefault: false,
      customerTenant: {
        id: 'ecc31fb7-039e-457b-8720-225400aba209',
        name: 'ЗАО Потапов',
        inn: '6742747851',
        phone: '+79557730473',
        partnershipStatus: 'accepted',
        employees: [{
          fullName: 'Ширяева Надежда',
          phone: '79001112203',
          email: null,
          jobTitle: 'Sales Analyst',
        }],
      },
      responsibleEmployee: {
        id: '692274e5-6fd9-49ba-bc44-13f1f51626f5',
        firstName: 'Лаврентий',
        lastName: 'Овчинников',
        secondName: null,
      },
    },
  ];
  setTimeout(() => resolve({ data }), 1000);
});

export const createEmployee = (payload: any) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/employees',
  payload: getObjectWithoutEmptyFields(payload),
});

export const fetchContractors = () => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contractors',
});

export const fetchContractorsAutocomplete = (
  query: string,
  addressId: string | null = null,
  category?: string,
) => sendApiRequest<TContractorsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/contractors',
  params: {
    query,
    addressId,
    category,
  },
});

export const fetchDriversAutocomplete = (name: string) => sendApiRequest<TAutocompleteDrivers>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/order_drivers',
  params: { name },
});

export const createContractor = (payload: any) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: '/:tenant_id/contractors',
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const fetchContractorsSuggestions = (query: string) => sendApiRequest<TContractor[]>({
  method: httpMethods.GET,
  endpoint: '/autocomplete/organizations',
  params: { query },
});

export const fetchUserRoles = () => sendApiRequest<TUserRolesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/roles',
});

export const fetchVehicleInformation = (vehicleId: string) => sendApiRequest<TVehicleInformationResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/vehicles/${vehicleId}`,
});

export const removeVehicle = (vehicleId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/vehicles/${vehicleId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editDonkey = (vehicleId: string, payload: TEditVehicleInformationRequest) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/donkeys/${vehicleId}`,
  payload,
});

export const editTrailer = (vehicleId: string, payload: TEditVehicleInformationRequest) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/trailers/${vehicleId}`,
  payload,
});

export const editTruck = (vehicleId: string, payload: TEditVehicleInformationRequest) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/trucks/${vehicleId}`,
  payload,
});

export const fetchEmployeeInformation = (employeeId: string) => sendApiRequest<TEmployeeResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/employees/${employeeId}`,
});

export const patchEmployee = (employeeId: string, payload: any) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/employees/${employeeId}`,
  payload,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const editEmployeeDriverLicense = (
  employeeId: string,
  payload: TEmployeeDriverLicense,
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/employees/${employeeId}/driver_licenses`,
  payload: { payload },
  requestOptions: {
    showError: true,
    message: tt('resourcesControl.shared.errors.editEmployeeRole'),
    serverErrorFirst: true,
  },
});

export const editEmployeePassport = (
  employeeId: string,
  payload: TEmployeePassport,
) => sendApiRequest({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/employees/${employeeId}/passports`,
  payload: { payload },
  requestOptions: {
    showError: true,
    message: tt('resourcesControl.shared.errors.editEmployeeRole'),
    serverErrorFirst: true,
  },
});

export const editEmployeeRole = (
  { roles, employeeId }: { roles: { id: string }[], employeeId: string },
  requestOptions = {
    showError: true,
    message: tt('resourcesControl.shared.errors.editEmployeeRole'),
    serverErrorFirst: true,
  },
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/employees/${employeeId}/role`,
  payload: { roles },
  requestOptions,
});

export const removeEmployee = (employeeId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/employees/${employeeId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getPossibleAccountTypes = (login: string) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/possible_account_types',
  params: { login },
});

export const createCorporateAccount = (
  employeeId: string,
  { login, password, sendGenerated }: TCreateCorporateAccountRequestPayload,
) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/employees/${employeeId}/user`,
  payload: getObjectWithoutEmptyFields({
    login,
    password,
    sendGenerated,
  }),
});

export function makeRequestToEmployeeEmailChange({ newEmail, employeeId }: { newEmail: string, employeeId: string }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/employees/${employeeId}/change_email_request`,
    payload: { newEmail },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('resourcesControl.shared.errors.changeEmail'),
    },
  });
}

export function confirmEmployeeEmailChange({ code, employeeId }: { code: string, employeeId: string }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/employees/${employeeId}/change_email_request/confirmation`,
    payload: { code },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('resourcesControl.shared.errors.confirmEmailChange'),
    },
  });
}

export function updateCorporateAccountPassword({ password, employeeId }: { password: string, employeeId: string }) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/employees/${employeeId}/password`,
    payload: { password },
  });
}

export function updatePhoneForCorporateAccount({ newPhone, employeeId }: { newPhone: string, employeeId: string }) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/employees/${employeeId}/user_phone`,
    payload: { newPhone },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('resourcesControl.shared.errors.updatePhone'),
    },
  });
}

export function inviteEmployee({ login, employeeId }: { login: string, employeeId: string }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/employees/${employeeId}/invitation`,
    payload: { login },
  });
}

export function revokeEmployeeInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/invitations/${invitationId}/revocation`,
  });
}

export function fetchEmployeeNotificationSettings() {
  return sendApiRequest<TEmployeeNotificationSettingsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/notifications/employee_settings',
  });
}

export function editEmployeeNotificationSettings(payload: any) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: '/:tenant_id/notifications/employee_settings',
    payload: { settings: payload },
  });
}

export function getEmailsForNotifications() {
  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/possible_notification_emails',
  });
}

export function requestConfirmationCode(email: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/email_confirmations/request_code',
    payload: { email },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('resourcesControl.shared.errors.requestConfirmationCode'),
    },
  });
}

export function confirmCodeForEmail(email: string, confirmationCode: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/email_confirmations/confirm_code',
    payload: {
      email,
      confirmationCode,
    },
  });
}

export function vehiclesAutocomplete(licensePlate: string, vehicleUnitTypes: EVehicleUnitType[]) {
  return sendApiRequest<TAutocompleteOrderVehiclesResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/autocomplete/order_vehicles',
    params: {
      licensePlate,
      vehicleUnitTypes,
    },
  });
}

export const fetchDepartments = () => sendApiRequest<TDepartmentsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/departments',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const fetchAvailableDepartments = () => sendApiRequest<TDepartmentsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/available_departments',
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export function editVehicleLegality(vehicleId: string, payload: any) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/vehicles/${vehicleId}/legality`,
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const uploadVehicleDocument = (
  vehicleId: string,
  fileData: TFileData,
) => sendApiRequest<TDocumentResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/vehicles/${vehicleId}/attachments`,
  payload: fileData,
});

export const deleteVehicleDocument = (
  vehicleId: string,
  documentId: string,
) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/vehicles/${vehicleId}/attachments/${documentId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const uploadEmployeeDocument = (
  employeeId: string,
  fileData: TFileData,
) => sendApiRequest<TDocumentResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/employees/${employeeId}/attachments`,
  payload: fileData,
});

export const deleteEmployeeDocument = (
  employeeId: string,
  documentId: string,
) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/employees/${employeeId}/attachments/${documentId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const updateEmployeeDisplayContactDetails = (
  employeeId: string,
  displayContactDetails: boolean,
) => sendApiRequest({
  method: httpMethods.PATCH,
  endpoint: `/:tenant_id/employees/${employeeId}/display_contact_details`,
  payload: { displayContactDetails },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});
