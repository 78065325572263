import { computed } from 'vue';

import { getActualAuthToken, getActualAuthTokenOld } from '@/api/sendApiRequest';
import logger from '@/logger';
import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import {
  connectConsumer,
  closeConnection,
  getConsumerInstance,
  getCurrentTenantId,
  getUrl,
} from './index';
import { addTaskToStack } from './tasksStack';

const iActionCableUpdateAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.actionCableUpdate));

export const openWsConnection = () => {
  getConsumerInstance();
};

const setUrlToConsumer = async () => {
  const consumer = await getConsumerInstance();

  const isRefreshAccessTokenAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refreshAccessToken);
  const fetchFn = isRefreshAccessTokenAvailable ? getActualAuthToken : getActualAuthTokenOld;
  const tokens = await fetchFn() as any;

  const tenantId = getCurrentTenantId();

  if (!tokens?.accessToken || !tenantId) {
    const message = '[setUrlToConsumer] accessToken or tenantId is undefined. Logout.';

    logger.warn(message);
    throw new Error(message);
  }

  if (iActionCableUpdateAvailable.value) {
    consumer._url = getUrl({
      tenantId,
      token: tokens?.accessToken || '',
    });
  } else {
    consumer.url = getUrl({
      tenantId,
      token: tokens?.accessToken,
    }) || '';
  }
};

export const disconnectConsumer = async () => {
  const consumer = await getConsumerInstance();

  if (consumer) {
    consumer.disconnect();
  }
};

export const reconnectWithNewUrl = async () => {
  const isRefreshAccessTokenAvailable = checkFeatureIsEnabled(EExperimentalFeatures.refreshAccessToken);
  const removeTask = addTaskToStack();

  if (isRefreshAccessTokenAvailable) {
    try {
      await disconnectConsumer();
      await setUrlToConsumer();
      await connectConsumer();

      removeTask();
    } catch {
      await closeConnection();
      logger.log('[reconnectWithNewUrl] setUrlToConsumer().catch');
      store.dispatch('app/logout');
    }
  } else {
    await disconnectConsumer();
    try {
      await setUrlToConsumer();
    } catch {
      closeConnection();
      // TODO: временный лог
      // eslint-disable-next-line
      console.log('[reconnectWithNewUrl] setUrlToConsumer().catch');
      store.dispatch('app/logout');
      return;
    }

    connectConsumer();
    removeTask();
  }
};
