import {
  ComputedRef,
  Ref,
  computed,
  onBeforeUnmount,
  ref,
} from 'vue';

import useStore from '@/store/useStore';
import useViewState from '@/composables/useViewState';
import { TDomainError, TSubscription } from '@/types';
import { createBillingAccount, updatePlan } from '@/contexts/billingContext/services';
import {
  EBillingPlanCode,
  EBillingPlanCodeStatus,
  ETenantSubscriptionsEventType,
} from '@/contexts/billingContext/domain/constants';
import { EBillingPlanSelectionSteps } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/constants';
import { TChangePlanSelectionStepFunction } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/types';
import { useTenantSubscriptionsWsChannel } from '@/contexts/billingContext/application/useTenantSubscriptionsWsChannel';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { formatDate, isToday } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';

import { TBillingShipperForm } from '../components/BillingShipperForm/domain/types';
import BillingShipperForm from '../components/BillingShipperForm/index.vue';
import BillingCarrierForm from '../components/BillingCarrierForm/index.vue';
import { TBillingCarrierForm } from '../components/BillingCarrierForm/domain/types';

type TUseView = {
  shipperFormRef: Ref<InstanceType<typeof BillingShipperForm> | null>,
  carrierFormRef: Ref<InstanceType<typeof BillingCarrierForm> | null>,
  planCode: Ref<EBillingPlanCode | null>,
  isShipperFormShown: ComputedRef<boolean>,

  emit: (event: 'close', ...args: unknown[]) => void,
  changePlanSelectionStep: TChangePlanSelectionStepFunction,
};

export const useConnectPlan = (
  {
    shipperFormRef,
    carrierFormRef,
    planCode,
    isShipperFormShown,

    emit,
    changePlanSelectionStep,
  }: TUseView,
) => {
  const store = useStore();

  const activeSubscription = computed(() => store.state.tenants.currentTenantInfo?.subscriptions?.find(
    (plan: TSubscription) => plan.status === EBillingPlanCodeStatus.active));

  const isTenantVerified = computed(() => !!store.state.tenants.currentTenantInfo?.verifiedAt);

  const { isLoading, setViewStateAs } = useViewState();

  const isSubmitDisabled = computed(
    () => !!(shipperFormRef.value?.isSubmitDisabled || carrierFormRef.value?.isSubmitDisabled),
  );

  const currentRef = computed(() => (isShipperFormShown.value ? shipperFormRef.value : carrierFormRef.value));

  /**
   * Флаг состояния "загрузки" кнопки "Начать пользоваться", на следующем после выбора тарифа экране (для всех тарифов, кроме тарифа ГВ).
   * Данная кнопка запускает несколько шагов: получение данных о тенанте (информация по подпискам), списка тенантов и раутинг к начальной странице.
   *
   * Так как подписка активируется автоматически в Lago (кроме тарифа ГВ),
   * но на бекенде часть операций по обновлению подписки выполняется асинхронно, может быть временной лаг,
   * когда вернется не полная информация по подпискам, если ее запросить сразу после подключения тарифа.
   *
   * Данный флаг блокирует кнопку "Начать пользоваться" пока не прийдет событие по сокетам, с информацией о том, что подписка успешно обновилась.
   * Это гарантирует, что по нажатию на кнопку пользователь получит актуальную информацию по подпискам и не попадет в выше описанный временной лаг.
   * */
  const isContinueButtonLoading = ref(false);

  const {
    connectToTenantSubscriptionsChannel,
    closeTenantSubscriptionsChannelConnection,
  } = useTenantSubscriptionsWsChannel({
    [ETenantSubscriptionsEventType.subscriptionPlanUpdated]: () => {
      isContinueButtonLoading.value = false;
    },
  });

  const updateBillingPlan = (
    payload: TBillingShipperForm | TBillingCarrierForm,
    planCodeValue: EBillingPlanCode,
  ) => {
    updatePlan({
      planCode: planCodeValue,
      ...payload,
    })
      .then(() => {
        changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
      })
      .catch((errors: TDomainError | null) => {
        if (errors) {
          currentRef.value?.setResponseErrors(errors);
        }

        isContinueButtonLoading.value = false;
      });
  };

  const createBillingPlan = (
    payload: TBillingShipperForm | TBillingCarrierForm,
    planCodeValue: EBillingPlanCode,
  ) => {
    createBillingAccount({
      planCode: planCodeValue,
      ...payload,
    })
      .then(() => {
        // Здесь идет проверка на planCode.value !== EBillingPlanCode.cargoOwner,
        // потому что у cargoOwner нет последнего шага (экран с большим пальцем и кнопкой "Начать пользоваться"),
        // поэтому диспатч и закрытие модального окна должны происходить на этом этапе.
        // У всех остальных планов есть последний шаг, и диспатч и закрытие модального окна происходят там.
        if (planCode.value !== EBillingPlanCode.cargoOwner) {
          changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
        } else {
          store.dispatch('tenants/fetchCurrentTenantInfo');

          emit('close');
        }
      })
      .catch((errors: TDomainError | null) => {
        if (errors) {
          currentRef.value?.setResponseErrors(errors);
        }

        isContinueButtonLoading.value = false;
      });
  };

  const onSubmit = () => {
    const planCodeValue = planCode.value;

    if (!planCodeValue) {
      return;
    }

    setViewStateAs.loading();

    currentRef.value?.onSubmit()
      .then((payload: TBillingShipperForm | TBillingCarrierForm) => {
        const subscriptionAtInUTC = formatDate(payload.subscriptionAt, {
          inputFormat: DATE_FORMAT.fullDate,
          outputFormat: DATE_FORMAT.UTCformat,
        });

        if (checkFeatureIsEnabled(EExperimentalFeatures.addUpdateTenantInfoByWsEvent)
          && isTenantVerified.value
          && isToday(subscriptionAtInUTC)
          && planCodeValue !== EBillingPlanCode.cargoOwner
        ) {
          isContinueButtonLoading.value = true;

          connectToTenantSubscriptionsChannel();
        }

        if (activeSubscription.value) {
          updateBillingPlan(payload, planCodeValue);
        } else {
          createBillingPlan(payload, planCodeValue);
        }
      },
      )
      .catch(() => {})
      .finally(setViewStateAs.done);
  };

  onBeforeUnmount(closeTenantSubscriptionsChannelConnection);

  return {
    isLoading,
    isSubmitDisabled,
    isContinueButtonLoading,

    onSubmit,
  };
};
