import { TSearchState } from './types';

/**
 * Набор ключей в сторе для хранения значений из полей поиска таблиц.
 * Значение из поля поиска может быть общим для нескольких таблиц (пример, раздел "Партнеры")
 *
 * При добавлении новых ключей для таблиц с поиском, при наличии у таблиц(ы) пагинации, вносим соответствующие изменения в DATA_FOR_RESET_PAGINATION. Это необходимо для реализации
 * сброса пагинации при переходе между разделами.
 * */
export enum ESearchStateSection {
  directions = 'directions',
  operationalDocuments = 'operationalDocuments',
  partnerships = 'partnerships',
  partnershipsContracts = 'partnershipsContracts',
  partnershipResources = 'partnershipResources',
}

export const DEFAULT_SEARCH_STATE: TSearchState = {
  directions: null,
  operationalDocuments: null,
  partnerships: null,
  partnershipsContracts: null,
  partnershipResources: null,
};
