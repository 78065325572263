<template>
  <Modal
    class="billing-plan-selection-modal"
    :class="{
      'billing-plan-selection-modal_with-footer': isStepChangeTenantInfo,
      'billing-plan-selection-modal_with-body-scroll': isStepChangeTenantInfo,
    }"
    :visible="visible"
    :width="940"
    :closable="!isStepCompleteConnection"
    destroyOnClose
  >
    <template v-if="!isStepCompleteConnection" #title>
      <ArrowSvg
        v-if="isStepChangeTenantInfo"
        class="billing-plan-selection-modal__title-icon"
        @click="changePlanSelectionStep(EBillingPlanSelectionSteps.planSelection)"
      />

      <p class="billing-plan-selection-modal__title">
        {{ modalTitle }}
      </p>
    </template>

    <template #closeIcon>
      <CloseSvg
        @click="onClose"
      />
    </template>

    <BillingPlanSelectionModalContent
      ref="billingContentRef"
      :currentStep="step"
      :changePlanSelectionStep="changePlanSelectionStep"
      @close="onClose"
    />

    <template #footer>
      <Button
        v-if="isStepChangeTenantInfo"
        type="primary"
        :loading="billingContentRef?.isLoading"
        :disabled="billingContentRef?.isSubmitDisabled"
        @click="billingContentRef?.onSubmit"
      >
        {{ submitButtonTitle }}
      </Button>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
} from 'vue';
import { Button, Modal } from 'ant-design-vue';

import tt from '@/i18n/utils/translateText';
import CloseSvg from '@/assets/svg/16x16/close.svg';
import ArrowSvg from '@/assets/svg/16x16/long-arrow-right.svg';

import { useView } from './application/useView';
import BillingPlanSelectionModalContent from './components/BillingPlanSelectionModalContent/index.vue';
import { EBillingPlanSelectionSteps } from './domain/constants';

export default defineComponent({
  name: 'BillingPlanSelectionModal',
  components: {
    Modal,
    BillingPlanSelectionModalContent,
    CloseSvg,
    ArrowSvg,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible'],
  setup(_, { emit }) {
    const billingContentRef = ref<InstanceType<typeof BillingPlanSelectionModalContent> | null>(null);

    const {
      step,
      submitButtonTitle,
      modalTitle,
      isStepChangeTenantInfo,
      isStepCompleteConnection,

      changePlanSelectionStep,
    } = useView(billingContentRef);

    const onClose = () => {
      changePlanSelectionStep(EBillingPlanSelectionSteps.planSelection);

      emit('update:visible', false);
    };

    return {
      step,
      billingContentRef,
      submitButtonTitle,
      modalTitle,
      isStepChangeTenantInfo,
      isStepCompleteConnection,
      EBillingPlanSelectionSteps,

      tt,
      onClose,
      changePlanSelectionStep,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
