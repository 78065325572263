export const SET_TENANTS = 'SET_TENANTS';
export const SET_TENANT_INFO = 'SET_TENANT_INFO';
export const IS_LOADING = 'IS_LOADING';
export const SET_CURRENT_TENANT = 'SET_CURRENT_TENANT';
export const SET_LAST_TENANT_ID = 'SET_LAST_TENANT_ID';
export const ADD_TENANT_TO_LIST = 'ADD_TENANT_TO_LIST';
export const REMOVE_TENANT_FROM_LIST = 'REMOVE_TENANT_FROM_LIST';
export const SELECT_TENANT = 'SELECT_TENANT';
export const RESET_STATE = 'RESET_STATE';
export const SET_REFERENCE_DATA = 'SET_REFERENCE_DATA';
export const UPDATE_EMPLOYEE_VIEW_SETTINGS_COLUMNS = 'UPDATE_EMPLOYEE_VIEW_SETTINGS_COLUMNS';
export const SET_IS_EMPLOYEES_AUTO_ASSIGNMENT_BANNER_VISIBLE = 'SET_IS_EMPLOYEES_AUTO_ASSIGNMENT_BANNER_VISIBLE';
