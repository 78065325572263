export enum EDigitalQueuePageType {
  booking = 'booking',
  schedule = 'schedule',
}

export enum EDigitalQueuePageView {
  timeline = 'timeline',
  table = 'table',
}

export enum EDigitalQueuePageTab {
  timeSlots = 'timeSlots',
  scheduleTemplates = 'scheduleTemplates',
}

export enum EBookingReservationState {
  lock = 'lock',
  reservation = 'reservation',
}
